import React from "react";
import Helmet from 'react-helmet'
import Layout from "../components/layout";

var pageTitle = "Contact";
var pageDesc = 'For more information about “It Takes a Titan: The Campaign for Cal State Fullerton” contact University Advancement: 657-278-5287 / giving@fullerton.edu.';

export default (props) => (
  <React.Fragment>
  <Layout locationInfo={props.location}>
    <Helmet>
      <title>{pageTitle}</title>
      <body className="contact" />
      
      <meta name="description" content={pageDesc} />

      {/* Schema.org markup for Google+ */}
      <meta itemprop="name" content={pageTitle} />
      <meta itemprop="description" content={pageDesc} />

      {/* Twitter Card data */}
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDesc} />

      {/* Open Graph data */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDesc} />
    </Helmet>
    <section style={{backgroundColor: '#EFEDE6', padding: '50px 0',}}>
      <article className="fixed">
        <br /><br /><br />
        <h1 class="center">General Contact</h1>
        <p className="center">
          <strong>University Advancement</strong><br />
          Cal State Fullerton<br />
          2600 Nutwood Avenue, Suite 850<br />
          Fullerton, CA 92831<br />
          657-278-5287<br />
          <a href="mailto:giving@fullerton.edu">giving@fullerton.edu</a>
        </p>
      </article>
    </section>
    <section style={{padding: '50px 0', }}>
      <article className="fixed">
        <h2 className="center">Colleges &amp; Divisions</h2>
        <div className="row">
          <div className="col-6">
            <h3>College of the Arts</h3>
            <strong>Ann Steichen</strong><br />
            Director of Development<br />
            657-278-7124<br />
            <a href="mailto:asteichen@fullerton.edu">asteichen@fullerton.edu</a>
          </div>
          <div className="col-6">
            <h3>Titan Athletics</h3>
            <strong>Jeffrey Gilstrap</strong><br />
            Senior Director of Development, Athletics<br />
            657-278-4516<br />
            <a href="mailto:jgilstrap@fullerton.edu ">jgilstrap@fullerton.edu </a>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <h3>College of Engineering and Computer Science, College of Natural Sciences &amp; Mathematics, Fullerton Arboretum</h3>
            <strong>Michael Karg</strong><br />
            Executive Director of Development<br />
            657-278-3348<br />
            <a href="mailto:mkarg@fullerton.edu">mkarg@fullerton.edu</a>
          </div>
          <div className="col-6">
            <h3>College of Communications, College of Education, Student Affairs</h3>
            <strong>Katie McGill</strong><br />
            Executive Director of Development<br />
            657-278-8231<br />
            <a href="mailto:kmcgill@fullerton.edu">kmcgill@fullerton.edu</a>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <h3>College of Health and Human Development</h3>
            <strong>Carlos Leija</strong><br />
            Associate Vice President<br />
            657-278-4869<br />
            <a href="mailto:cleija@fullerton.edu">cleija@fullerton.edu</a>
          </div>
          <div className="col-6">
            <h3>College of Humanities and Social Sciences</h3>
            <strong>Melissa Martinez</strong><br />
            Senior Director of Development<br />
            657-278-4847<br />
            <a href="mailto:melissamartinez@fullerton.edu">melissamartinez@fullerton.edu</a>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <h3>College of Business and Economics</h3>
            <strong>Chrissy Vaughn</strong><br />
            Director of Development<br />
            657-278-7433<br />
            <a href="mailto:cmvaughn@Fullerton.edu">cmvaughn@Fullerton.edu</a><br />
          </div>
        </div>
      </article>
    </section>
  </Layout>
  </React.Fragment>
);
